@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Custom utility for hiding the scrollbar */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
  }

  /* Tablet-specific styles */
  @media (min-width: 768px) and (max-width: 1280px) {
    /* Apply to Guest Dropdown Wrapper */
    .c-gdw {
      display: block;
      width: 100%;
    }

    /* Apply to Guest Dropdown Fields */
    .c-gdl {
      display: block;
    }

    .c-gdi {
      display: block;
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}
